<template>
  <div class="is-flex is-flex-direction-column af-footer">
    <div v-if="cbrVisible" class="is-flex cbr-block mb-2">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <a class="mb-2">{{ $t("af:header.title.call_us") }}</a>
        <div class="has-text-weight-bold has-text-grey">
          {{
            isMortgage
              ? $t("af:header.title.mortgage_phone_number")
              : $t("af:header.title.phone_number")
          }}
        </div>
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center" v-if="showCallMeBack">
        <a class="mb-1">{{ $t("af:header.title.call_me_back_label") }}</a>
        <b-button :style='{"fontSize":"14px"}' @click="openCallMeBackModal">
          {{ $t("af:footer.title.call_me_back") }}
        </b-button>
      </div>
    </div>
    <div class="is-flex is-justify-content-center link-block">
      <a :href="$t('af:footer.link.privacy_policy')" target="_blank">{{
        $t("af:footer.title.privacy_policy")
      }}</a>
      <a :href="$t('af:footer.link.user_agreement')" target="_blank">{{
        $t("af:footer.title.user_agreement")
      }}</a>
      <a :href="$t('af:footer.link.instacash')" target="_blank"
        >{{ $t("af:footer.title.instacash") }}{{ year }}</a
      >
    </div>
  </div>
</template>

<script>
import eventBus, { EVENTS } from "@/utils/eventBus";
import { CALL_ME_BACK_TYPE, UI_CONFIGS } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "TheFooter",
  computed: {
    showCallMeBack() {
      return !this.$route.fullPath.includes("/registration");
    },
    year() {
      return new Date().getFullYear();
    },
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    cbrVisible() {
      return this.$route.meta.mortgage
        ? this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST_MORTGAGE) ===
            "true"
        : this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST) === "true";
    },
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
  methods: {
    openCallMeBackModal() {
      eventBus.$emit(EVENTS.OPEN_CALL_ME_BACK_MODAL, CALL_ME_BACK_TYPE.FOOTER);
    },
  },
};
</script>

<style scoped lang="scss">
.af-footer {
  padding: 20px;
}

.mbh {
  .af-footer {
    background: #f5f5f5;
  }

  a {
    color: #616161;
    font-size: 13px;
    font-weight: normal;
  }
}

a {
  color: #adb7c4;
  font-size: 12px;
  margin: 0 8px;
  text-align: center;
}

.cbr-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.link-block {
  flex-direction: column;
  gap: 8px;
}

@media only screen and (min-width: 768px) {
  .cbr-block {
    flex-direction: row;
    align-items: baseline;
    gap: 32px;
  }

  .link-block {
    flex-direction: row;
  }
}
</style>
